<template>
  <div class="box">
    <el-dialog title="編輯學校排名" :visible.sync="addTag" width="60%">
      <el-card
        class="box-card"
        v-for="(input, index) in getData.ranks"
        :key="index"
        style="margin:0 0 20px 0"
      >
        <div slot="header" class="clearfix">
          <span class="content-title">
            {{ input.rank_year }} {{ input.rank_name[lang] }}
          </span>
          <el-button
            style="float: right; padding: 3px 0;"
            class="text-danger"
            type="text"
            @click="removeInput(index)"
          >
            <i class="fa fa-trash"></i>
          </el-button>
        </div>
        <div class="row" style="margin:10px 0">
          <div class="col-sm-2" style="line-height:45px">排名</div>
          <div class="col-sm-10">
            <el-input v-model="input.rank">
              <template slot="prepend">#</template>
            </el-input>
          </div>
        </div>
        <div class="row" style="margin:10px 0">
          <div class="col-sm-2" style="line-height:45px">排名年份</div>
          <div class="col-sm-10">
            <el-input v-model="input.rank_year"></el-input>
          </div>
        </div>
        <div class="row" style="margin:10px 0">
          <div class="col-sm-2" style="line-height:45px">學校排名(TW)</div>
          <div class="col-sm-10">
            <el-input
              v-model="input.rank_name['tw']"
              placeholder="學校排名(TW)"
            ></el-input>
          </div>
        </div>
        <div class="row" style="margin:10px 0">
          <div class="col-sm-2" style="line-height:45px">學校排名(CN)</div>
          <div class="col-sm-10">
            <el-input
              v-model="input.rank_name['cn']"
              placeholder="學校排名(CN)"
            ></el-input>
          </div>
        </div>
        <div class="row" style="margin:10px 0">
          <div class="col-sm-2" style="line-height:45px">學校排名(EN)</div>
          <div class="col-sm-10">
            <el-input
              v-model="input.rank_name['en']"
              placeholder="學校排名(EN)"
            ></el-input>
          </div>
        </div>
        <div class="row" style="margin:10px 0">
          <div class="col-sm-2" style="line-height:45px">排名鏈接</div>
          <div class="col-sm-10">
            <el-input v-model="input.rank_source_url" placeholder="排名鏈接">
              <template slot="prepend">https://</template>
            </el-input>
          </div>
        </div>
      </el-card>
      <div style="margin-top:20px" class="text-center">
        <el-button style="width:100%" type="success" @click="addInput">
          <i class="fa fa-plus"></i>
        </el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addTag = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <div class="container sections section">
      <div class="section-title">
        {{ $t("schools.Ranks") }}
      </div>
      <hr />
      <div v-if="getData.ranks.length > 0" style="margin-top: 20px">
        <div
          class="content"
          v-for="(rank, index) in getData.ranks"
          :key="index"
        >
          <div class="content-title">
            {{ rank.rank_year }} <span v-if="rank.site_name">[{{ rank.site_name }}] </span> {{ rank.rank_name["en"] }}
          </div>
          <div class="content-title-value">
            <span class="text-warning" style="margin-right:5px"
              ><i class="fas fa-tag"></i
            ></span>
            <b style="margin-right:5px">#{{ rank.rank }}</b>
            <router-link
              :to="{
                name: 'LandingPage',
                params: { rank: rank.id }
              }"
              target="_blank"
            >
              <small>(查看詳細排名)</small>
            </router-link>
          </div>
        </div>
      </div>
      <div v-else style="margin-top: 20px">
        {{ $t("schools.Not reported") }}
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index.js";
export default {
  components: {},

  mixins: [mixin],

  props: ["getData", "lang", "theme"],
  data() {
    return {
      inputs: [],
      addTag: false
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    showTitle(val) {
      return this.$t("schools.Campus Life Section." + this.trimStr(val));
    },
    addInput() {
      this.ranks.push({
        rank_year: "",
        rank_name: {
          tw: "",
          en: "",
          cn: ""
        },
        rank_source_url: "",
        rank: 0
      });
    },
    removeInput(index) {
      this.inputs.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";
</style>
