import Vue from "vue";
import axios from "@/common/interceptors.service";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);
export default {
  getSchoolCurrentSlug(slug) {
    return Vue.axios({
      method: "get",
      url: `${process.env.VUE_APP_SCHOOL_BACKEND_URL}/api/school-slugs/redirect`,
      params: { slug }
    });
  },
  schoolGet(resource, params) {
    return Vue.axios({
      method: "get",
      url: `${process.env.VUE_APP_SCHOOL_BACKEND_URL}/api/${resource}`,
      params: params
    }).catch(error => {
      throw error.response;
    });
  },
  schoolDownload(resource, params) {
    return Vue.axios({
      method: "get",
      url: `${process.env.VUE_APP_SCHOOL_BACKEND_URL}/api/${resource}`,
      params: params,
      responseType: "blob"
    }).catch(error => {
      throw error.response;
    });
  },
  getSchools(params) {
    return this.schoolGet("schools", params);
  },
  getSchool(school) {
    return this.schoolGet(`schools/${school}`);
  },
  getRange() {
    return this.schoolGet("schools/intro");
  },
  getTable(params) {
    return this.schoolGet("columns", params);
  },
  async getSection(school, section) {
    let sectionName = "";
    let link = "";
    switch (section) {
      case "overview":
        link = `schools/${school}`;
        sectionName = "overview";
        break;
      case "admissions":
        link = `schools/${school}/admission`;
        sectionName = "admission";
        break;
      case "financials":
        link = `schools/${school}/financial`;
        sectionName = "financial";
        break;
      case "academic":
        link = `schools/${school}/academic`;
        sectionName = "academic";
        break;
      case "campus-life":
        link = `schools/${school}/campus`;
        sectionName = "campus";
        break;
      case "students":
        link = `schools/${school}/student`;
        sectionName = "student";
        break;
      case "salaries":
        link = `schools/${school}`;
        sectionName = "overview";
        break;
      case "ranks":
        link = `schools/${school}/ranks`;
        sectionName = "ranks";
        break;
    }
    const res = await this.schoolGet(link);
    return res[sectionName];
  },
  getTableFile(params) {
    return this.schoolDownload("excel/update-column", params);
  }
};
