<template>
  <div class="box">
    <div class="profile-header container">
      <div class="container">
        <div class="header-title">
          {{ getData.overview[lang] }}
        </div>
        <!-- PC -->
        <div class="d-none d-md-block">
          <div class="header-cards" >
            <div class="cards-item">
              <div class="item-body">
                <i
                  v-if="getData.student.coed === 'Coed'"
                  class="fas fa-user-friends"
                ></i>
                <i v-else class="fas fa-user"></i>
              </div>
              <div class="item-text">{{ getData.student.coed }}</div>
            </div>
            <div class="cards-item">
              <div class="item-body">
                {{ toThousands(getData.student.undergrad_count) }}
              </div>
              <div class="item-text">UNDERGRADUATES</div>
            </div>
            <div class="cards-item">
              <div class="item-body">
                {{ toThousands(getData.student.undergrad_m_count) }}
              </div>
              <div class="item-text">
                MEN - {{ getData.student.undergrad_m_pct }}%
              </div>
            </div>
            <div class="cards-item">
              <div class="item-body">
                {{ toThousands(getData.student.undergrad_w_count) }}
              </div>
              <div class="item-text">
                WOMEN - {{ getData.student.undergrad_w_pct }}%
              </div>
            </div>
            <div class="cards-item">
              <div class="item-body">
                {{ toThousands(getData.student.grad_count) }}
              </div>
              <div class="item-text">GRADUATES</div>
            </div>
          </div>
        </div>
         <!-- 手机端  -->
        <div class="d-block d-md-none">
          <div class="row header-cards-phone" >
            <div class="col-6">
              <div class="cards-item">
                <div class="item-body">
                  <i
                    v-if="getData.student.coed === 'Coed'"
                    class="fas fa-user-friends"
                  ></i>
                  <i v-else class="fas fa-user"></i>
                </div>
                <div class="item-text">{{ getData.student.coed }}</div>
              </div>
            </div>
            <div class="col-6">
              <div class="cards-item">
                <div class="item-body">
                  {{ toThousands(getData.student.undergrad_count) }}
                </div>
                <div class="item-text">UNDERGRADUATES</div>
              </div>
            </div>
            <div class="col-6">
              <div class="cards-item">
                <div class="item-body">
                  {{ toThousands(getData.student.undergrad_m_count) }}
                </div>
                <div class="item-text">
                  MEN - {{ getData.student.undergrad_m_pct }}%
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="cards-item">
                <div class="item-body">
                  {{ toThousands(getData.student.undergrad_w_count) }}
                </div>
                <div class="item-text">
                  WOMEN - {{ getData.student.undergrad_w_pct }}%
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="cards-item">
                <div class="item-body">
                  {{ toThousands(getData.student.grad_count) }}
                </div>
                <div class="item-text">GRADUATES</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="sections container">
      <!-- Admissions 完成-->
      <div class="section">
        <div class="admissions">
          <div class="section-title">
            <div>
              <i class="fas fa-graduation-cap"></i>
              {{ $t("schools.Admissions") }}
            </div>
            <div>
              <router-link
                :to="`/schools/${this.$route.params.schoolId}/admissions`"
              >
                See More
              </router-link>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Entrance Difficulty") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.admission.entrance_difficulty) }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Overall Admission Rate") }}
            </div>
            <div class="content-title-value">
              {{ getData.admission.acceptance_pct_overall }}% of
              {{ toThousands(getData.admission.applicant_overall_count) }}
              applicants were admitted
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Regular Admission Deadline") }}
            </div>
            <div class="content-title-value">
              <span v-if="getData.admission.rd_deadline_is_rolling === 0">{{
                getData.admission.rd_deadline
              }}</span>
              <span v-if="getData.admission.rd_deadline_is_rolling === 1"
                >Rolling</span
              >
            </div>
          </div>
          <hr />
          <div class="section-subtitle">
            {{ showTitle("QUALIFICATIONS OF ENROLLED FRESHMEN") }}
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Average GPA") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.admission.avg_gpa) }}
            </div>
          </div>
          <div class="content" v-if="getData.admission.avg_sat_math">
            <div class="content-title">
              {{ showTitle("SAT Math") }}
            </div>
            <div class="content-title-value">
              {{ getData.admission.avg_sat_math }} average <br />
              {{ getData.admission.avg_sat_math_min }} -
              {{ getData.admission.avg_sat_math_max }} range of middle 50%
            </div>
          </div>
          <div class="content">
            <div class="content-title">{{ showTitle("SAT EBRW") }}</div>
            <div class="content-title-value">
              <span v-if="getData.admission.avg_sat_e">
                {{ getData.admission.avg_sat_e }} average <br />
                {{ getData.admission.avg_sat_english_min }} -
                {{ getData.admission.avg_sat_english_max }} range of middle 50%
              </span>
              <span v-else>Not reported</span>
            </div>
          </div>
          <div class="content">
            <div class="content-title">{{ showTitle("ACT Composite") }}</div>
            <div class="content-title-value">
              <span v-if="getData.admission.avg_act">
                {{ getData.admission.avg_act }} average <br />
                {{ getData.admission.avg_act_min }} -
                {{ getData.admission.avg_act_max }} range of middle 50%
              </span>
              <span v-else>Not reported</span>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <!-- Financials 完成-->
      <div class="section">
        <div class="financials">
          <div class="section-title">
            <div>
              <i class="fas fa-search-dollar"></i>
              {{ $t("schools.Financials") }}
            </div>
            <div>
              <router-link
                :to="`/schools/${this.$route.params.schoolId}/financials`"
              >
                See More
              </router-link>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Cost of Attendance") }}
            </div>
            <div class="content-title-value">
              {{
                showReport(toThousands(getData.money.total_tuition), "", "$")
              }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">{{ showTitle("Tuition and Fees") }}</div>
            <div class="content-title-value">
              {{ showReport(toThousands(getData.money.tuition_fee), "", "$") }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">{{ showTitle("Room and Board") }}</div>
            <div class="content-title-value">
              {{ showReport(toThousands(getData.money.room_fee), "", "$") }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Average Percent of Need Met") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.money.finaid_freshmen_avg_pct_met, "%") }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Average Freshman Award") }}
            </div>
            <div class="content-title-value">
              {{
                showReport(
                  toThousands(getData.money.finaid_freshmen_avg_award),
                  "",
                  "$"
                )
              }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Average Indebtedness of 2018 Graduates") }}
            </div>
            <div class="content-title-value">
              {{
                showReport(
                  toThousands(getData.money.student_indebtedness),
                  "",
                  "$"
                )
              }}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <!-- Academic 完成-->
      <div class="section">
        <div class="academic">
          <div class="section-title">
            <div>
              <i class="fas fa-file-alt"></i>
              {{ $t("schools.Academic") }}
            </div>
            <div>
              <router-link
                :to="`/schools/${this.$route.params.schoolId}/academic`"
              >
                See More
              </router-link>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Academic Calendar System") }}
            </div>
            <div class="content-title-value">
              {{ getData.academic.calendar_system }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("General Education/Core Curriculum") }}
            </div>
            <div class="content-title-value">
              <span v-if="getData.academic.has_gened === 1">
                Required for most or all students
              </span>
              <span v-if="getData.academic.has_gened === 0">
                Not required for most or all students
              </span>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Full-Time Faculty Teaching Undergraduates") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.academic.fulltime_faculty_count) }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Regular Class Size (Students)") }}
            </div>
            <div class="content-title-value">
              <div class="chart-bar">
                <div class="row" v-if="getData.academic.class_size_9_pct">
                  <div class="col-md-1">
                    2-9
                  </div>
                  <div class="col-md-11">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.academic.class_size_9_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.academic.class_size_19_pct">
                  <div class="col-md-1">
                    10-19
                  </div>
                  <div class="col-md-11">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.academic.class_size_19_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.academic.class_size_29_pct">
                  <div class="col-md-1">
                    20-29
                  </div>
                  <div class="col-md-11">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.academic.class_size_29_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.academic.class_size_39_pct">
                  <div class="col-md-1">
                    30-39
                  </div>
                  <div class="col-md-11">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.academic.class_size_39_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.academic.class_size_49_pct">
                  <div class="col-md-1">
                    40-49
                  </div>
                  <div class="col-md-11">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.academic.class_size_49_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.academic.class_size_99_pct">
                  <div class="col-md-1">
                    50-99
                  </div>
                  <div class="col-md-11">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.academic.class_size_99_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row" v-if="getData.academic.class_size_100_pct">
                  <div class="col-md-1">
                    Over 100
                  </div>
                  <div class="col-md-11">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.academic.class_size_100_pct"
                    ></el-progress>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <!-- Campus Life 完成 -->
      <div class="section">
        <div class="campus-life">
          <div class="section-title">
            <div>
              <i class="fas fa-university"></i>
              {{ $t("schools.Campus Life") }}
            </div>
            <div>
              <router-link
                :to="`/schools/${this.$route.params.schoolId}/campus-life`"
              >
                See More
              </router-link>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ getData.admission.ao_city }} {{ showTitle("Population") }}
            </div>
            <div class="content-title-value">
              {{ getData.campus.city_population }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Nearest Metropolitan Area") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.campus.nearest_metro) }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Freshman Housing Guarantee") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.campus.housing_guarantee) }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Students in College Housing") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.campus.housing_pct, "% of all students") }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Athletic Conferences") }}
            </div>
            <div class="content-title-value">
              {{ getData.campus.athletic_conference }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Mascot") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.campus.mascot) }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Sororities") }}
            </div>
            <div class="content-title-value">
              {{
                showReport(getData.campus.sororities, "% of women participate")
              }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Fraternities") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.campus.frats, "% of men participate") }}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <!-- Students 完成 -->
      <div class="section">
        <div class="students">
          <div class="section-title">
            <div>
              <i class="fas fa-users"></i>
              {{ $t("schools.Students") }}
            </div>
            <div>
              <router-link
                :to="`/schools/${this.$route.params.schoolId}/students`"
              >
                See More
              </router-link>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Ethnicity of Students from US") }}
            </div>
            <div class="content-title-value">
              <div class="chart-bar">
                <div class="row">
                  <div class="col-md-3">
                    American Indian/Alaskan Native
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.student.indian_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    Asian
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.student.asian_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    Black/African-American
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.student.black_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    Hispanic/Latino
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.student.hispanic_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    Multi-race (not Hispanic/Latino)
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.student.mixed_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    Native Hawaiian/ Pacific Islander
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.student.pi_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    White
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.student.white_pct"
                    ></el-progress>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    Unknown
                  </div>
                  <div class="col-md-9">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="32"
                      :color="theme"
                      :percentage="getData.student.unknown_pct"
                    ></el-progress>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("International Students") }}
            </div>
            <div class="content-title-value">
              <span v-if="getData.student.intl_pct">
                {{ getData.student.intl_pct }}% from
                {{ getData.student.intl_countries_count }} countries
              </span>
              <span v-else>
                Not reported
              </span>
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("First-Year Students Returning") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.student.return_rate, "%") }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Students Graduating Within 4 Years") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.student.graduate_4_yrs, "%") }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{
                showTitle(
                  "Graduates Offered Full-Time Employment Within 6 Months"
                )
              }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.student.employment_pct, "%") }}
            </div>
          </div>
          <div class="content">
            <div class="content-title">
              {{ showTitle("Graduates Pursuing Advanced Study Directly") }}
            </div>
            <div class="content-title-value">
              {{ showReport(getData.student.pursue_grad_studies_pct, "%") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index.js";
export default {
  components: {},

  mixins: [mixin],

  props: ["getData", "lang", "theme"],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {
    this.$nextTick(this.showTitle);
  },

  methods: {
    showTitle(val) {
      return this.$t("schools.Overview Section." + this.trimStr(val));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";

.header-cards {
  display: flex;
}
.header-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
  color: white;
}

.cards-item:not(:last-child) {
  margin-right: 8px;
}
.cards-item {
  min-width: 120px;
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
}
.header-cards-phone .cards-item {
  width: 100%;
  margin-bottom: 20px;
}

.cards-item .item-body {
  height: 40px;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.11;
  letter-spacing: 0.19px;
}
.cards-item .item-text {
  padding-top: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  white-space: nowrap;
}
.header-cards-phone .cards-item .item-text {
  letter-spacing: normal;
}
</style>
