<template>
  <div class="box">
    <div class="container sections section">
      <div class="section-title">
        {{ $t("schools.Salaries") }}
      </div>
      <hr />
      <div v-if="major.length > 0" style="margin-top: 20px">
        <div class="content" v-for="(item, index) in major" :key="index">
          <div class="content-title">
            <span class="text-warning" style="margin-right:5px">
              <i class="fas fa-book"></i>
              Major:
            </span>
            <span v-if="item.name">{{ item.name }}</span>
          </div>
          <div class="content-title-value">
            <b class="text-warning" style="margin-right:5px">
              <i class="fas fa-comment-dollar"></i>
              Earnings:
            </b>
            <b style="margin-right:5px">{{ item.salary }}</b>
          </div>
          <hr style="border-top:1px solid rgba(0, 0, 0, 0.1) " />
        </div>
      </div>
      <div v-else style="margin-top: 20px">
        {{ $t("schools.Not reported") }}
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index.js";
export default {
  components: {},

  mixins: [mixin],

  props: ["getData", "lang", "theme"],
  data() {
    return {
      inputs: [],
      addTag: false,
      major: [
        {
          name:
            "Business Administration, Management and Operations (First Professional Degree)",
          salary: "$161,400"
        },
        {
          name: "Law (First Professional Degree)",
          salary: "$158,200"
        },
        {
          name:
            "Advanced/Graduate Dentistry and Oral Sciences (Graduate/Professional Certificate)",
          salary: "$143,200"
        },
        {
          name: "Computer Science (Bachelor’s Degree)",
          salary: "$128,900"
        },
        {
          name: "Statistics (Bachelor’s Degree)",
          salary: "$126,100"
        }
      ]
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    showTitle(val) {
      return this.$t("schools.Campus Life Section." + this.trimStr(val));
    },
    addInput() {
      this.ranks.push({
        rank_year: "",
        rank_name: {
          tw: "",
          en: "",
          cn: ""
        },
        rank_source_url: "",
        rank: 0
      });
    },
    removeInput(index) {
      this.inputs.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";
</style>
